<template>
  <v-card class="card-reel">
    <v-img height="220px" :src="srcImage" class="width-card grey darken-4 img-reel">
      <div class="actions-card">
        <v-spacer></v-spacer>
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="1" v-bind="attrs" v-on="on" icon color="primary">
              <v-icon color="#fafafa" size="20">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('edit', item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('remove', item)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-img>
    <div :class="`notice-details`">
        <span class="date-notice">
          {{ item.publication_date }}
        </span>
        <h3 class="title-notice">
          {{ item.title_press_notes_cards }}
        </h3>
    </div>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
export default {
  props: {
    item: Object,
    srcImage: String,
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-reel {
  .actions-card {
    position: absolute;
    right: 0;
    top: 0;
  }
  .container-slide {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 80%;
    transform: translate(-50%, -50%);
    .notice-details {
      margin: 15px 30px 20px !important;
    }
    @media screen and (max-width: 550px) {
      max-width: 90%;
    }
  }

  .notice-details{
    height: 150px;
    margin: 10px 15px;
    .date-notice {
      display: inline-block;
      color: #00ACA0;
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .title-notice {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: bolder;
      color: #6C757D !important;
    }
    .link-notice {
      color: #004D86;
      text-decoration: none;
      font-size: 14.2px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
.img-reel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
</style>
