<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formPressNotes" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="2">
            <label for="title-press-notes-cards">Fecha de publicacion</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              type="date"
              id="title-press-notes-cards"
              v-model="payload['publication_date']"
              outlined
              dense
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="title_press_notes">Titulo</label>
          </v-col>
          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_press_notes"
              v-model="payload['title_press_notes_cards']"
              outlined
              dense
              placeholder="Título"
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file-press-notes">Imagen de portada</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file-press-notes"
              prepend-icon=""
              v-model="images"
              outlined
              dense
              placeholder="Selecciona una imagen"
              :autocomplete="autocomplete"
              :rules="payloadEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <label for="content">Contenido</label>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-md-editor
              :disabled-menus="[]"
              id="content"
              class="mb-4"
              v-model="payload['content']"
              height="500px"
              @upload-image="handleUploadImage"
            ></v-md-editor>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    payloadEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      autocomplete: 'off',
      isEdited: this.payloadEdited ? true : false,
      images: null,
      payload: this.payloadEdited ? { ...this.payloadEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formPressNotes.validate()
    },
    reset() {
      this.payload['publication_date'] = ''
      this.payload['title_press_notes_cards'] = ''
      this.payload['content'] = ''
      this.images = null
      this.$refs.formPressNotes.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      const formData = new FormData()
      formData.append('publication_date', this.payload.publication_date)
      formData.append('title_press_notes_cards', this.payload['title_press_notes_cards'])
      formData.append('content', this.payload['content'])
      if (this.images) {
        formData.append('file_press_notes', this.images)
      }
      // console.log(this.payload, this.images)
      const DTO = {
        id: this.payload.id,
        formData,
      }
      // console.log(DTO)
      if (this.isEdited) {
        this.$emit('beforeUpdate', DTO)
      } else {
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    async handleUploadImage(event, insertImage, files) {
      for (const file of files) {
        const { data, status } = await this.$store.dispatch('saveFile', { file_press_notes: file })
        insertImage({
          url: this.loadImage(data.img_press_notes),
          desc: '',
        })
      }
      // Here is just an example
    },
  },
}
</script>

<style lang="scss" scoped></style>
