<template>
  <v-card elevation="0">
    <HeaderView title="Notas de prensa" @openToAdd="openDialog" :noSearch="true"></HeaderView>
    <ListPressNotes :pressNotes="pressNotes" @edit="openEdit" @remove="openRemove" />
    <WDialog
      width="1200"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormPressNotes
          v-if="dialog"
          :loading="loading"
          :payloadEdited="pressNotesEdited"
          @beforeUpdate="updatePressNotes"
          @beforeCreate="createPressNotes">
        </FormPressNotes>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import FormPressNotes from "@/components/forms/FormPressNotes.vue";
import ListPressNotes from "./ListPressNotes.vue";
import HeaderView from "@/components/HeaderView.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import usePressNotes from "@/composables/usePressNotes";

export default {
  components: {
    FormPressNotes,
    HeaderView,
    ListPressNotes,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      pressNotesEdited,
      pressNotes,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getPressNotesList,
      createPressNotes,
      updatePressNotes,
      removePressNotes,
      openRemove,
    } = usePressNotes();

    return {
      dialog,
      pressNotesEdited,
      pressNotes,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      getPressNotesList,
      createPressNotes,
      updatePressNotes,
      removePressNotes,
      openRemove
    };
  },
};
</script>

<style lang="scss" scoped></style>
