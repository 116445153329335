import {ref, computed} from '@vue/composition-api';
import store from '@/store/index';

const usePressNotes = () => {
  // base data
  const dialog = ref(false);
  const pressNotesEdited = ref (null);
  const pressNotes = ref ([]);
  const loading = ref (false);

  // computed properties
  const titleDialog = computed (
    () => (pressNotesEdited.value ? 'Editar Nota de prensa' : 'Agregar Nota de prensa')
  );
  const subtitleDialog = computed (
    () =>
      pressNotesEdited.value
        ? 'Rellene los campos correctamente para modificar los datos de la nota de prensa'
        : 'Rellene los campos correctamente para agregar una nueva nota de prensa'
  );

  // methods
  const openDialog = () => {
  dialog.value = true;
  };
  const openEdit = pressNotes => {
    dialog.value = true;
    pressNotesEdited.value = {...pressNotes};
  };
  const closeDialog = () => {
    dialog.value = false;
    pressNotesEdited.value = null;
  };

  // crud
  const getPressNotesList = async () => {
    const {data, status} = await store.dispatch ('fetchPressNotes');
    if (status != 200) return;
    pressNotes.value = data;
  };

  const createPressNotes = async pressNotes => {
    loading.value = true;
    const {data, status} = await store.dispatch ('createPressNotes', pressNotes);
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getPressNotesList();
    closeDialog ();
  };

  const updatePressNotes = async pressNotes => {
    loading.value = true;
    const {data, status} = await store.dispatch ('updatePressNotes', pressNotes);
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getPressNotesList();
    closeDialog ();
  };

  const removePressNotes = async user => {
    const val = await store.dispatch ('generateConfirmDialog', {});
    if (!val) return;
    const {data, status} = await store.dispatch ('removePressNotes', user);
    if (status != 200 && status != 201 && status != 204) return;
    await getPressNotesList ();
  };

getPressNotesList();



  return {
    dialog,
    pressNotesEdited,
    pressNotes,
    loading,
    //crud
    createPressNotes,
    updatePressNotes,
    openRemove: removePressNotes,
    // methods
    openDialog,
    openEdit,
    closeDialog,
    // computed
    titleDialog,
    subtitleDialog,

  };
};

export default usePressNotes;
